const Helpers = {
    openMobileMenu(btn) {
        document.querySelector(btn).addEventListener('click', function () {
            this.classList.toggle('active')
            document.body.classList.toggle('open-menu')

            Helpers.changeLogo('.header .logo img', 'img/logo.svg', 'img/logo-white.svg')
        })
        
    },
    changeLogo(item, logo, logoNew) {
        const el = document.querySelector(item)
        el.getAttribute('src') === logo ?
            el.setAttribute('src', logoNew) :
            el.setAttribute('src', logo)
    },
    addMenuClass(menuEl, className) {
        document.querySelectorAll(menuEl).forEach(el => {
            if (el.href === window.location.href) {
                el.parentNode.classList.add(className)
            }
        })
    }
}

export const openMobileMenu = Helpers.openMobileMenu
export const addMenuClass = Helpers.addMenuClass